.link {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #171818;

  transition: color 250ms ease-in-out;

  &:hover {
    color: #1d1d1d; /* Світло-сірий для незначної зміни */
  }

  &:active {
    color: #0e0e0e;
  }
}
