.form {
  background-color: #fff;
  border-radius: 8px;
  padding: 24px;

  display: flex;
  flex-direction: column;
  gap: 16px;

  margin-bottom: 24px;
}

.codeContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.spanContainer {
  height: 48px;
  width: 62px;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid #858b8a;
}

.span {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.01em;
  text-align: left;
  color: #171818;
}

.innerForm {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 4px;
}

.title {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 0.01em;
  text-align: left;
  color: #4d5151;
}
