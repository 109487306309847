.button {
  width: 40px;
  height: 40px;
  padding: 0px 16px 0px 16px;
  border-radius: 8px;
  border: none;
  background-color: transparent;
  outline: none;

  box-sizing: border-box;
  cursor: pointer;

  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 0.01em;
  color: #171818;

  transition: background-color 250ms ease-in-out, border-color 250ms ease-in-out;

  &.isActive {
    background-color: #f7f8fc;
    border: 1px solid #e7e7e7;
  }
}
