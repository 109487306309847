.all {
  display: flex;
  align-items: stretch;
  height: auto;
}

.container {
  padding: 12px 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  border-radius: 8px;
  border: 1px solid #858b8a;
}

.buttonsContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: space-around;

  max-height: 304px;
  overflow-y: scroll;
}

.button {
  //   height: 48px;
  width: 90%;
  // max-width: 394px;
  padding: 14px 16px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;

  border: none;
  outline: none;
  border-radius: 8px;
  box-sizing: border-box;

  background-color: #00813e;

  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.01em;
  color: #fff;
  cursor: pointer;

  transition: background-color 250ms ease-in-out, color 250ms ease-in-out;

  &.active {
    background-color: #00813e;
    color: #fff;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;

    &:hover {
      background-color: #026533;
    }
  }

  &.inactive {
    background-color: transparent;
    color: #171818;

    &:hover {
      background-color: #e4e6e6;
    }
  }
}

.subContainer {
  display: flex;
  align-items: center;
  gap: 16px;
}

.titleContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2px;
  text-align: start;
}

.name {
  max-width: 200px;
  word-wrap: break-word;
}

.subTitleContainer {
  line-height: 1;
}

.subTitle {
  font-size: 12px;
  font-weight: 400;
  opacity: 0.7;
  line-height: 0;
}

.input {
  width: 48px;
  text-align: center;
  outline: none;
  border-radius: 8px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border: 1px solid #858b8a;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.01em;
  color: #171818;
  // line-height: 100%;
  flex: 1;
  &.disabled {
    visibility: 0;
    opacity: 0;
  }
  &:focus-visible {
    border-color: #3359e8;
  }
}
