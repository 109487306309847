.container {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  align-items: flex-start;
  width: 100%;
}

.subContainer {
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 0.01em;
  color: #4d5151;
  text-align: left;
}

.titleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dateInput {
  font-family: "Manrope", sans-serif;
  width: 100%;
  height: 48px;
  padding: 12px 12px 12px 12px;
  border-radius: 8px;
  border: 1px solid #858b8a;
  box-sizing: border-box;
  outline: none;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.01em;
  text-align: center;
  transition: border-color 250ms ease-in-out;
}

.button {
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.01em;
  color: #4d5151;
  text-align: left;

  cursor: pointer;

  background-color: transparent;
  border: none;

  transition: color 250ms ease-in-out;

  &:hover {
    color: #0004e5;
  }
}
