.container {
  max-width: 422px;
  width: 100%;
  display: "flex";
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;
  gap: 8px;
}

.title {
  font-size: 32px;
  font-weight: 600;
  letter-spacing: 0.01em;
  line-height: 1.5;
  color: "#171818";
  margin: 0;
  text-align: start;
}

.subTitle {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.02em;
  line-height: 1.5;
  color: "#4D5151";
  margin: 0;
  text-align: start;
}
