.container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  margin-bottom: 32px;
}

.button {
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.title {
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.01em;
  color: #171818;
}
