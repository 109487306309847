.container {
  position: relative;
  overflow-x: scroll;
}

.customTable {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 83px;
}

.customTable th,
.customTable td {
  padding: 12px;
  border-bottom: 1px solid #ddd;
  text-align: left;
}

.customTable tr {
  &.accent {
    background-color: #ffee2270;
  }
  &.secondAccent {
    background-color: #2fff0033;
  }
  &.thirdAccent {
    background-color: #c000003d;
  }
}

.customTable tr:nth-child(even) {
  background-color: #f7f8fc;
  &.accent {
    background-color: #ffee2270;
  }
  &.secondAccent {
    background-color: #2fff0033;
  }
  &.thirdAccent {
    background-color: #c000003d;
  }
}

.customTable th {
  background-color: #f4f4f4;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 0.01em;
  color: #4d5151;
  padding: 8px;
}

.userInfo {
  display: flex;
  align-items: center;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  margin-right: 12px;
  object-fit: cover;
}

.avatarEmpty {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  margin-right: 12px;
  background-color: #d9d9d9;
}

.nameContainer {
  display: flex;
  flex-direction: column;
  max-width: 200px;
}

.name {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 0.02em;
  text-align: left;
  color: #171818;
  word-wrap: break-word;
}

.email {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.02em;
  text-align: left;
  color: #4d5151;
}

.status {
  padding: 4px 16px 4px 16px;
  border-radius: 8px;
  border-width: 0.5px;
  border-style: solid;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 0.02em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.status.registered {
  background-color: #e0f7e9;
  color: #28a745;
  border-color: #2e8506;
}

.status.notRegistered {
  background-color: #fffcea;
  color: #b95e04;
  border-color: #b95e04;
}

.actions {
  display: flex;
  gap: 8px;
  justify-content: flex-end;
}

.editBtn,
.infoBtn {
  background: none;
  border: none;
  cursor: pointer;
}

.editBtn:hover,
.infoNtn:hover {
  opacity: 0.7;
}

.role {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.02em;
  color: #171818;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
}

.titleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.listItem {
  margin: 0;
}
