.container {
  display: flex;
  height: 100vh;
  flex-direction: row;
  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
}

.sidebarInner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: scroll;
  height: 100%;
}

.sidebar {
  width: 100%;
  max-width: 364px;
  background-color: #171818;
  padding: 32px;
  overflow-y: scroll;
  z-index: 200;
  min-width: 364px;

  @media screen and (max-width: 900px) {
    position: absolute;
    top: 0;
    bottom: 0;
    left: -450px;

    transition: left 250ms ease-in-out;

    &.open {
      left: 0;
    }
  }
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 150; // Повинен бути вище, ніж контент, але нижче ніж сайдбар
}

.title {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 0.01em;
  text-align: left;
  color: #ffffff;
  margin-bottom: 48px;
}

.navContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 32px;
  height: 100%;
}

.nav {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
}

.navLink {
  text-align: start;
  padding: 10px 16px;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 0.01em;
  color: #ffffff;
  text-decoration: none;
  border-radius: 12px;

  display: flex;
  align-items: center;
  gap: 12px;
  &.active {
    background-color: #3359e8;
  }
}

.header {
  @media screen and (min-width: 900px) {
    display: none;
  }
  background-color: #171818;
  width: 100%;
  padding: 24px;
  height: 88px;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.headerTitle {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 0.01em;
  text-align: left;
  color: #ffffff;
}

.empty {
  visibility: hidden;
  opacity: 0;
}

.buttonIcon {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #3359e8;
  border-radius: 8px;
  border: none;
  cursor: pointer;

  transition: background-color 250ms ease-in-out;

  &:hover {
    background-color: #213ed4;
  }
}

.content {
  width: 100%;
  padding: 32px;
  overflow-y: scroll;

  @media screen and (max-width: 900px) {
    padding-left: 24px;
    padding-right: 24px;
  }
}

.userContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 32px;
  @media screen and (max-width: 900px) {
    margin-bottom: 48px;
  }
}

.userInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}

.pageTitle {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 0.01em;
  text-align: left;
  color: #171818;
}

.userName {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 0.01em;
  color: #171818;
}

.avatarContainer {
  position: relative;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 8px;
}

.verify {
  position: absolute;
  right: 0px;
  bottom: 0px;
}
