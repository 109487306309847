.fileEditContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.fileName {
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #4d5151;
  max-width: 300px;
}

.deleteButton {
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
}
