.button {
  width: 100%;
  max-width: 422px;

  font-size: 14px;
  line-height: 1.5;
  letter-spacing: 0.001em;
  font-weight: 600;
  color: #171818;

  border: 1px solid #858b8a;
  background-color: #fff;
  border-radius: 8px;
  padding: 10px;
  cursor: pointer;
  box-sizing: border-box;

  transition: background-color 0.25s ease-in-out, color 0.25s ease-in-out;

  &:hover {
    background-color: #e4e6e6;
  }
  &:active {
    background-color: #cfd2d1;
  }
  &:disabled {
    background-color: #cfd2d1;
    color: #cfd2d1;
  }

  &.width {
    max-width: 100%;
  }
}

.smallButton {
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: 0.001em;
  font-weight: 500;
  color: #171818;

  border: 1px solid #858b8a;
  background-color: transparent;
  border-radius: 8px;
  padding: 10px 20px;
  cursor: pointer;
  box-sizing: border-box;

  transition: background-color 0.25s ease-in-out, color 0.25s ease-in-out;

  &:hover {
    background-color: #e4e6e6;
  }
  &:active {
    background-color: #cfd2d1;
  }
  &:disabled {
    background-color: #cfd2d1;
    color: #cfd2d1;
  }
}
