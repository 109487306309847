.container {
}

.link {
  text-decoration: none;
  color: #3359e8;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 600;
  letter-spacing: 0.02em;
}

.text {
  color: "#4D5151";
  text-decoration: none;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 400;
  letter-spacing: 0.01em;
}
