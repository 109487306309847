.title {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 0.01em;
  text-align: left;
  color: #171818;
}

.count {
  font-weight: 700;
  letter-spacing: 0.01em;

  color: #3359e8;
}
