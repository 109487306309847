.container {
  display: flex;
  flex-direction: column;

  gap: 24px;
}

.innerContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.input {
  outline: none;
  border: 1px solid #858b8a;
  width: 57px;
  height: 48px;
  border-radius: 8px;
  padding-left: 24px;

  box-sizing: border-box;

  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.01em;

  &:focus-visible {
    border-color: #3359e8;
  }
}

.buttonsContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
}
