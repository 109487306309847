.container {
  display: flex;
  align-content: center;
  gap: 16px;
  width: 100%;

  &.bigModal {
    @media screen and (min-width: 900px) {
      width: 50%;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.deleteButton {
  background-color: #fff1f1;
  width: 116px;
  height: 40px;
  border-radius: 8px;
  box-sizing: border-box;
  border: 1px solid #ec1617;

  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 0.01em;
  cursor: pointer;
  color: #ec1617;
}

.backButton {
  background-color: #e2e5ea;
  width: 116px;
  height: 40px;
  border-radius: 8px;
  box-sizing: border-box;
  border: 1px solid #858b8a;

  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 0.01em;
  cursor: pointer;
  color: #171818;

  transition: background-color 250ms ease-in-out, color 250ms ease-in-out;

  &:hover {
    background-color: #d9dde4;
  }

  &:active {
    background-color: #bfc6d1;
  }

  &:disabled {
    background-color: #f6f7f8;
    color: #a0abba;
  }
}

.applyButton {
  background-color: #3359e8;
  height: 40px;
  width: 100%;
  border: none;
  border-radius: 8px;

  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 0.01em;
  cursor: pointer;
  color: #fff;

  gap: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  transition: background-color 250ms ease-in-out;

  &:hover {
    background-color: #213ed4;
  }

  &:active {
    background-color: #2134ac;
  }

  &:disabled {
    background-color: #95bafb;
  }
}
