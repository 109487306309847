.customІelect {
  display: block;
  width: 100%;
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none;
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 8px 10px;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
}

.label {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 0.01em;
  text-align: left;
  color: #4d5151;
  margin-bottom: 4px;
}

.require {
  color: #c8290d;
}

.input {
  width: 100%;
  height: 48px;
  padding: 12px 12px 12px 16px;
  border-radius: 8px;
  border: 1px solid #858b8a;
  box-sizing: border-box;
  outline: none;

  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.01em;
  text-align: left;
  color: #171818;

  transition: border-color 250ms ease-in-out;

  &:focus-visible {
    border-color: #3359e8;
  }
}
