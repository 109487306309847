.form {
  background-color: #fff;
  border-radius: 8px;
  padding: 24px;

  display: flex;
  flex-direction: column;
  gap: 16px;

  margin-bottom: 24px;
  width: 100%;
}

.container {
  width: 100%;
}

.input {
  display: none;
}

.photoContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-bottom: 24px;
}

.image {
  width: 100px;
  height: 100px;
  object-fit: contain;
  border-radius: 8px;
}

.headerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  width: 100%;
  margin-bottom: 24px;
}

.selectedContainer {
  display: flex;
  flex-direction: column;
}

.selectedTitle {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 0.01em;
  text-align: left;
  color: #4d5151;
  margin-bottom: 4px;
}

.require {
  color: #c8290d;
}

.pay {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.payContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.payTitle {
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #4d5151;
}

.selectContainer {
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
  align-items: center;
}

.flex {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  gap: 8px;
}
