.button {
  width: 100%;
  max-width: 422px;

  font-size: 14;
  line-height: 1.5;
  letter-spacing: 0.001em;
  font-weight: 600;
  color: #ffffff;

  border: none;
  background-color: #3359e8;
  border-radius: 8px;
  padding: 10px;
  cursor: pointer;

  transition: background-color 0.25s ease-in-out;

  &:hover {
    background-color: #213ed4;
  }
  &:active {
    background-color: #2134ac;
  }
  &:disabled {
    background-color: #95bafb;
  }
}

.big {
  max-width: 100%;
}
