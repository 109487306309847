.container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
}

.addButton {
  height: 40px;
  padding: 9.5px 28px;
  min-width: 200px;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: 0.001em;
  font-weight: 600;
  color: #171818;

  border: 1px solid #858b8a;
  background-color: #3359e8;
  border-radius: 8px;
  padding: 10px;
  cursor: pointer;
  box-sizing: border-box;

  color: #fff;
  transition: background-color 0.25s ease-in-out;

  &:hover {
    background-color: #213ed4;
  }
  &:active {
    background-color: #2134ac;
  }
  &:disabled {
    background-color: #95bafb;
  }
}

.filterButton {
  height: 40px;
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  align-items: center;
  gap: 12px;

  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.01em;
  color: #171818;

  box-sizing: border-box;
  border: 1px solid #858b8a;
  border-radius: 8px;
  background-color: transparent;

  cursor: pointer;
}

.span {
  @media screen and (max-width: 1160px) {
    display: none;
  }
}

.inputContainer {
  position: relative;
  display: flex;
  max-width: 270px;
  height: 40px;
}

.input {
  height: 100%;
  width: 100%;
  padding-left: 48px;
  padding-right: 16px;

  border-radius: 8px;
  border: 1px solid #858b8a;

  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.01em;
  text-align: left;
  color: #171818;

  outline: none;

  &:focus-visible {
    border-color: #3359e8;
  }
}

.icon {
  position: absolute;
  align-self: center;
  left: 16px;
}
