.button {
  text-align: start;
  padding: 10px 16px;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 0.01em;
  color: #ffffff;
  border-radius: 12px;
  cursor: pointer;
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  gap: 12px;
}
