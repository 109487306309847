.form {
  background-color: #fff;
  border-radius: 8px;
  padding: 24px;

  display: flex;
  flex-direction: column;
  gap: 4px;

  margin-bottom: 24px;

  max-width: 600px;
  width: 100%;

  &.first {
    gap: 16px;
  }
  @media screen and (max-width: 899px) {
    margin-bottom: 0;
    &.first {
      margin-bottom: 24px;
    }
  }
}

.viewForm {
  background-color: #fff;
  border-radius: 8px;
  padding: 24px;

  display: flex;
  flex-direction: column;
  gap: 16px;

  margin-bottom: 24px;

  max-width: 600px;
  width: 100%;
  margin-bottom: 24px;
}

.title {
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #4d5151;
}

.container {
}

.input {
  display: none;
}

.photoContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-bottom: 24px;
}

.image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 8px;
}

.headerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  width: 100%;
  margin-bottom: 24px;
}

.employeeButtonsContainer {
  display: flex;
  gap: 8px;
  justify-content: space-between;
  align-items: center;
}

.warehouseContainer {
  display: flex;
  width: 900px;

  @media screen and (max-width: 899px) {
    width: auto;
    flex-direction: column;
    margin-bottom: 24px;
  }
}

.viewContainer {
  border: 1px solid #858b8a;
  border-radius: 8px;
  padding: 12px 16px;
  max-height: 485px;
  height: 100%;
  overflow-y: scroll;

  display: flex;
  flex-direction: column;
  gap: 16px;
}

.selectContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.providerContainer {
  display: flex;
  align-items: stretch;
  gap: 8px;
}

.listContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  &:not(:last-child) {
    margin-bottom: 16px;
  }
}
