.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
}
.label {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 0.01em;
  text-align: left;
  color: #4d5151;
  margin-bottom: 4px;
}

.require {
  color: #c8290d;
}

.input {
  font-family: "Manrope", sans-serif;

  width: 100%;
  height: 48px;
  padding: 12px 12px 12px 48px;
  border-radius: 8px;
  border: 1px solid #858b8a;
  box-sizing: border-box;
  outline: none;

  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.01em;
  text-align: left;

  transition: border-color 250ms ease-in-out;

  &:focus-visible {
    border-color: #3359e8;
  }

  &.bigInput {
    height: 96px;
  }
}

.inputWithoutIcon {
  padding-left: 16px;
}

.icon {
  position: absolute;
  left: 16px;
  bottom: 14px;
  width: 20px;
  height: 20px;
}
