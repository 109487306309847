.button {
  width: 100%;
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  margin-bottom: 16px;
}

.buttonText {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 0.01em;
  text-align: left;
  color: #6a706f;
}

.chevron {
  transition: transform 250ms ease-in-out;
  transform: rotate(0deg);

  &.chevronToggle {
    transform: rotate(-180deg);
  }
}

.linksContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  transition: max-height 250ms ease-in-out, opacity 250ms ease-in-out;
  overflow: hidden;
  max-height: 500px; /* Set a max height large enough for the content */
  opacity: 1;

  &.isClose {
    max-height: 0;
    opacity: 0;
  }
}
