.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.button {
  width: 112px;
  height: 40px;
  border: 1px solid #858b8a;
  background-color: transparent;
  border-radius: 8px;

  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 0.01em;
  color: #171818;

  cursor: pointer;

  transition: background-color 250ms ease-in-out;

  &:hover {
    background-color: #f7f8fc;
  }

  &:disabled {
    opacity: 0;
    visibility: hidden;
  }
}

.countContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
