.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}

.input {
  display: none;
}

.innerContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  height: 40px;
}

.inputCustom {
  width: 15px;
  height: 15px;
  border: 1.5px solid #171818;
  border-radius: 1.5px;
  margin-right: 15px;
  box-sizing: border-box;
  position: relative;
  display: inline-block;
}

.label {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.01em;
  text-align: left;
  color: #171818;

  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 16px;
}

.description {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: 0.02em;
  text-align: start;
  color: #4d5151;
}

.icon {
  width: 20px;
  height: 20px;
}
