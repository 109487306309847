.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}

.title {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 0.01em;
  text-align: left;
  color: #4d5151;
}

.value {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.01em;
  text-align: left;
  clear: #171818;
}

.containerInner {
  width: 100%;
  min-height: 48px;
  height: 100%;
  background-color: #f7f8fc;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 16px;
  box-sizing: border-box;
  border: 1px solid #e7e7e7;
  border-radius: 8px;
}

.containerInnerArea {
  font-family: "Manrope", sans-serif;
  width: 100%;
  background-color: #f7f8fc;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 16px;
  box-sizing: border-box;
  border: 1px solid #e7e7e7;
  border-radius: 8px;
  height: 96px;
}

.valueContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
}

.button {
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
}
