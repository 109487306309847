.form {
  background-color: #fff;
  border-radius: 8px;
  padding: 24px;

  display: flex;
  flex-direction: column;
  gap: 16px;

  margin-bottom: 24px;
  width: 100%;
}

.container {
  width: 100%;
}

.input {
  display: none;
}

.image {
  width: 100px;
  height: 100px;
  object-fit: contain;
  border-radius: 8px;
}

.headerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  width: 100%;
}
