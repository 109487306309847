.form {
  background-color: #fff;
  border-radius: 8px;
  padding: 24px;

  display: flex;
  flex-direction: column;
  gap: 16px;

  margin-bottom: 24px;
}
.input {
  display: none;
}

.photoContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-bottom: 24px;
}

.image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 8px;
}

.headerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  width: 100%;
}
