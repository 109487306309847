.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}

.title {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 0.01em;
  text-align: left;
  color: #4d5151;
}
