.container {
  max-width: 422px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
}

.title {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 0.01em;
  color: #4d5151;
}

.required {
  color: #c8290d;
}

.inputContainer {
  max-width: 422px;
  width: 100%;
  position: relative;
}

.input {
  outline: none;
  width: 100%;
  border: 1px solid #858b8a;
  padding: 14px 16px 14px 48px;
  border-radius: 8px;
  background-repeat: no-repeat;
  background-position: left 10px center; // Розміщення іконок зліва і справа
  background-size: 20px 20px; // Розмір іконок

  box-sizing: border-box;

  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.01em;

  &:focus-visible {
    border-color: #3359e8;
  }
}

.error {
  border-color: #ec1617;
}

.success {
  border-color: #2e8506;
}

.email {
  background-image: url("../../../assets/icons/auth/email.svg");
}

.password {
  background-image: url("../../../assets/icons/auth/lock.svg");
  padding-right: 40px;
}

.icon {
  position: absolute;
}

.button {
  padding: 0;
  position: absolute;
  top: 50%;
  right: 16px;
  background-color: transparent;
  border: none;
  transform: translateY(-50%);
  cursor: pointer;
}

.errorContainer {
  display: flex;
  align-items: center;
  gap: 12px;
  position: absolute;
  left: 8px;
  bottom: -18px;
}

.errorMessage {
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: 0.01em;
  font-weight: 600;
  color: #ec1617;
}
