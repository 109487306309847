.stepContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media screen and (min-width: 900px) {
    &.bigModal {
      width: 40%;
    }
  }
}

.stepSubContainer {
  display: flex;
  align-items: center;
  gap: 12px;
}

.step {
  width: 40px;
  height: 28px;
  border-radius: 8px;
  border: 1px solid #858b8a;
  background-color: #e1e4e9;
  //   padding: 3.5px 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.active {
    border: none;
    background-color: #3359e8;
  }
}

.stepText {
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0.01em;
  text-align: center;
  color: #4d5151;

  &.active {
    color: #fff;
  }
}

.text {
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #171818;
}

.textActive {
}
