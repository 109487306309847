.button {
  height: 40px;
  width: 100%;
  border: none;
  border-radius: 8px;
  background-color: #00813e;

  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 0.01em;
  color: #ffffff;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;

  cursor: pointer;
  &.link {
    height: auto;
    justify-content: space-between;
    padding: 12px 16px;
  }
}
