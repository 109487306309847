.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  padding: 24px;
  background-color: #f7f8fc;
  border-radius: 8px;
  max-height: 100vh;
  overflow-y: scroll;

  @media screen and (max-width: 768px) {
    padding: 24px 24px 48px 24px;
  }
}
