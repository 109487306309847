.container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
  gap: 24px;
  padding: 0 20px;

  @media screen and (max-width: 960px) {
    width: 100%;
  }
}

.inputsContainer {
  width: 100%;
  max-width: 422px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.passwordContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 24px;
}

.link {
  text-align: end;
  text-decoration: none;
  color: #3359e8;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 600;
  letter-spacing: 0.01em;
}
.buttonsContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
