.editTitleContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.editTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}

.title {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 0.01em;
  color: #171818;
}
.upSideButton {
  border: none;
  cursor: pointer;
  box-sizing: border-box;
  background-color: transparent;
}

.subTitle {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: 0.02em;
  text-align: left;
  max-width: 500px;
  color: #4d5151;
}

.header {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 32px;
}
.marginHeader {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 16px;
}

.upSide {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
